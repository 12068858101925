import React from 'react';
import Typed from 'react-typed';
import { scroller } from 'react-scroll';

const Hero = () => {

  const handleGetStarted = () => {
    scroller.scrollTo('cardsSection', {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className='main1'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h2 className='md:text-4xl text-[#ffffff] sm:text-3xl text-2xl h2-title-1 font-bold md:py-6'>
          ZEDXION Smart Chain
        </h2>
        <p className='text-[#ffffff] font-bold p-2'>
            Generate ZEDXION Wallets in Bulk
        </p>
        <h1 className='md:text-7xl text-[#ffffff] sm:text-4xl text-3xl font-bold md:py-6'>
          Create ZEDXION wallets in bulk quickly
        </h1>
        <div className='flex justify-center text-[#ffffff] items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
              and
          </p>
          <Typed
            className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['easily', 'conveniently', 'efficiently']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-xl text-xl font-bold text-[#ffffff]'>Simplifies the process of generating a series of ZEDXION wallets with just a few clicks. It's quick, easy, and convenient.</p>
        <button onClick={handleGetStarted} className='bg-[#1FC7D4] text-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto py-3 '>Get Started</button>
        <p className='font-bold text-[#ffffff]'>ZedWallet facilitates the creation and administration of digital wallets, empowering users to securely store and transact cryptocurrencies within the ZEDXION Network ecosystem.</p>
      </div>
    </div>
  );
};

export default Hero;
