import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import logo from '../assets/logo-footer.png';
import iconCex from '../assets/icon-cex.svg';
import iconNeobank from '../assets/icon-neobank.svg';
import iconFace from '../assets/icon-face.svg';
import iconG from '../assets/icon-g.svg';
import iconG2 from '../assets/icon-g2.svg';
import iconG3 from '../assets/icon-g3.svg';
import iconIn from '../assets/icon-in.svg';
import iconM from '../assets/icon-m.svg';
import iconTwitter from '../assets/icon-twitter.svg';
import iconYoutube from '../assets/icon-youtube.svg';
import iconZedbridge from '../assets/icon-zedbridge.svg';
import iconZeddex from '../assets/icon-zeddex.svg';
import iconZedscan from '../assets/icon-zedscan.svg';
import iconZedstake from '../assets/icon-zedstake.svg';
import iconZedtoken from '../assets/icon-zedtoken.svg';

const Footer = () => {
  return (
    <div className='footer'>
        <div className='max-w-[1240px] mx-auto py6 px-4 grid lg:grid-cols-2 footer-body gap-8'>
            <div className='footer-left'>
                {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>BULKWALLET.</h1> */}
                <div className='py-4 flex'><img src={logo} alt='/'/> <h3 className='w-full text-1xl font-bold text-[#000000] powerby'>Power by ZEDXION Smart Chain</h3></div>
                <p className='py-4'>ZedWallet is a tool enabling users to generate and manage digital wallets, allowing secure storage and transfer of cryptocurrencies within the "ZEDXION" network ecosystem.</p>
                <div className='flex justify socialbetween-all socialbetween1 md:w-[100%] my-6'>
                    <a href="https://zed-pay.com/" target="_blank" className="flex flex-wrap gap-2"><img src={iconNeobank} className="icon-f-1" />NeoBank (ZedPay)</a>
                    <a href="https://www.zedxion.io/en_US/" target="_blank" className="flex flex-wrap gap-2"><img src={iconCex} className="icon-f-1" />CEX1 (ZEDXIONI)</a>
                    <a href="https://www.zedcex.com/en_US/market" target="_blank" className="flex flex-wrap gap-2"><img src={iconCex} className="icon-f-1" />CEX2 (ZedCex)</a>
                    
                    {/*<FaFacebookSquare size={30} />
                    <FaInstagram size={30} />
                    <FaTwitterSquare size={30} />
                    <FaGithubSquare size={30} />
                    <FaDribbbleSquare size={30} />*/}
                </div>
                <div className='flex justify socialbetween-all socialbetween1 md:w-[100%] my-6'>
                    <a href="https://zedscan.net/" target="_blank" className="flex flex-wrap gap-2"><img src={iconZedscan} className="icon-f-1" />ZedScan</a>
                    <a href="https://zeddex.com" target="_blank" className="flex flex-wrap gap-2"><img src={iconZeddex} className="icon-f-1" />ZedDex</a>
                    <a href="https://bridge.zedxion.com" target="_blank" className="flex flex-wrap gap-2"><img src={iconZedbridge} className="icon-f-1" />ZedBridge</a>
                    <a href="https://stake.zedxion.com/" target="_blank" className="flex flex-wrap gap-2"><img src={iconZedstake} className="icon-f-1" />ZedStake</a>
                    <a href="https://token.zedxion.com/" target="_blank" className="flex flex-wrap gap-2"><img src={iconZedtoken} className="icon-f-1" />ZedToken</a>
                </div>
                <div className='flex socialbetween md:w-[100%] my-6'>
                    <a href="https://www.facebook.com/zedxioncoin" target="_blank" ><img src={iconFace} className="icon-f-2" /></a>
                    <a href="https://twitter.com/zedxionc" target="_blank" ><img src={iconTwitter} className="icon-f-2" /></a>
                    <a href="https://www.instagram.com/zedxion/" target="_blank" ><img src={iconG} className="icon-f-2" /></a>
                    <a href="https://www.youtube.com/channel/UCVbVWWdHHrpRkRL80_CoDZg" target="_blank" ><img src={iconYoutube} className="icon-f-2" /></a>
                     <a href="https://discord.com/invite/UDDb7FZF" target="_blank" ><img src={iconM} className="icon-f-2" /></a>
                    <a href="https://medium.com/subscribe/@zedxion_exchange" target="_blank" ><img src={iconG2} className="icon-f-2" /></a>
                    <a href="https://www.linkedin.com/company/zedxion-exchnage-crypto" target="_blank" ><img src={iconIn} className="icon-f-2" /></a>
                    <a href="https://github.com/Zedxioncryptocurrency" target="_blank" ><img src={iconG3} className="icon-f-2" /></a>                    
                </div>
                
            </div>
            <div className='footer-right'>
                <div className='flex footer-right-flex justify-between mt-6'>
                    <div className='footer-right-1'>
                        <h6 className='font-medium font-bold'>Company</h6>
                        <ul>
                            <li className='py-2 text-sm'>Delegate to BcsScan</li>
                            <li className='py-2 text-sm'><p className='btn-staking'>Staking</p></li>
                            <li className='py-2 text-sm'>Brand Assets</li>
                            <li className='py-2 text-sm'>Contact Us</li>
                            <li className='py-2 text-sm'>Terms & Privacy</li>
                            <li className='py-2 text-sm'>Bug Bounty</li>
                        </ul>
                    </div>
                    <div className='footer-right-1'>
                        <h6 className='font-medium font-bold'>Community</h6>
                        <ul>
                            <li className='py-2 text-sm'>API Documentation</li>
                            <li className='py-2 text-sm'>Knowledge Base</li>
                            <li className='py-2 text-sm'>Network Status</li>
                            <li className='py-2 text-sm'>Learn BSC</li>
                        </ul>
                    </div>
                    <div className='footer-right-1'>
                        <h6 className='font-medium font-bold'>Products & Services</h6>
                        <ul>
                            <li className='py-2 text-sm'>Advertise</li>
                            <li className='py-2 text-sm'>Explorer-as-a-Service (EaaS)</li>
                            <li className='py-2 text-sm'>API Plans</li>
                            <li className='py-2 text-sm'>Priority Support</li>
                            <li className='py-2 text-sm'>Blockscan</li>
                        </ul>
                    </div>
            
                </div>
            </div>
            
        </div>
        <div className='max-w-[1240px] mx-auto px-4 grid footer-body gap-8'>
           <p className='copyright'>ZEDXION Chain © 2024 | <a href="https://docs.zedscan.net/zedxion-whitepaper/" target="_blank" className="linkbtn" >WhitePaper</a></p>
        </div>
    </div>
  );
};

export default Footer;
